.center .slick-center {
  -ms-transform: scale(1.5);
  transform: scale(1.1);
}

.slick-track {
  margin-top: 9px;
}

.slick-arrow-icon-left,
.slick-arrow-icon-right {
  position: absolute;
  display: block;
  cursor: pointer;
  background: transparent;
  background-color: #8686863f;
  color: #fff;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  border: "none";
  outline: none;
  transition: 0.5s ease-in-out;
  z-index: 10;

  &:hover {
    outline: none;
    background: transparent;
    background-color: #7777779d;
    opacity: 1;

    &::before {
      opacity: 1;
    }
  }
}

.slick-arrow-icon-right {
  left: -0px;
}

.slick-arrow-icon-left {
  right: -0px;
}

// react-image-crop

.Crop-Controls {
  margin-bottom: 10px;
}

.Crop-Controls > * {
  margin-bottom: 3px;
}

img {
  max-width: 100%;
}

// react map marker

.number-icon {
  // background-image: url("../icons/lidomarker.png");
  background-color: #962f32;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
  font-size: 4mm;
  color: White;
  border-radius: 1cm;
}

.swiper-pagination-fraction-active {
  background-color: white !important;
}

.swiper-button-next::after {
  color: white;
}

.swiper-button-prev::after {
  color: white;
}

.swiper-pagination.swiper-pagination-fraction {
  color: white !important;
}

:focus {
  outline: none;
}

/* NOTE: Remove arrows from number textfields */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#root {
  overflow: hidden;
}
